import React, { useState, useEffect } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import isEmpty from 'is-empty';
import axios from 'axios';
import { EditorState, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

import { Button, CheckBox, Text, Layer, Heading } from 'grommet';
import { Edit } from 'grommet-icons';

import {
  socketSetQuestionLocked,
  socketSetNextQuestion,
  socketSetPrevQuestion,
  socketUpdateQuestionsOrder,
} from '../../sockets_client';

import { AppBar, ClubCard, ResponsesCard, EditQuestionsOrderPopup, QuestionTitle } from '../';
import { Left } from '../../assets/Left';
import { Right } from '../../assets/Right';
import { colours } from '../../App';
import { FormSecure } from '../../assets/FormSecure';
import { AppState, Club, ClubState, Role, RoomState } from '../../Types';
import { Box } from '../layout/Box';
import { Size } from '../../enums';

type ConnectedProps = {
  room: RoomState;
  club: ClubState;
};

const RoomHostPanelStaging = ({ room, club }: ConnectedProps) => {
  const [clubs, setClubArray] = useState<Club[]>([]);
  const [responseCounter, setResponseCounter] = useState({
    for: 0,
    against: 0,
    abstain: 0,
  });

  const [questionDisplay, setQuestionDisplay] = useState('');
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  const [closeRoomWindow, setCloseRoomWindow] = useState(false);

  /* question locked toggle */
  const [questionLocked, setQuestionLocked] = useState(false);
  const onToggleLock = () => {
    socketSetQuestionLocked(room.roomId, !questionLocked);
  };

  /* popup editor state */
  const [orderEditorOpen, setOrderEditorOpen] = React.useState(false);

  //get clubs
  useEffect(() => {
    axios.get('/club').then((res) => {
      let clubs = res.data
        .sort((a: Club, b: Club) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0))
        .filter((club: Club) => club.role !== 'host');
      setClubArray(clubs);
    });
  }, []);

  const onClickNextQuestion = () => {
    socketSetNextQuestion(room.roomId);
  };
  const onClickPrevQuestion = () => {
    socketSetPrevQuestion(room.roomId);
  };

  //when question changes
  useEffect(() => {
    setQuestionLocked(room.currentQuestion.questionLocked);
  }, [room.currentQuestion.questionLocked]);

  //when question changes
  useEffect(() => {
    const questionLabel = room.currentQuestion.value;
    if (!isEmpty(questionLabel)) {
      if (isEmpty(questionLabel.entityMap)) {
        questionLabel.entityMap = {};
      }
      const contentState = convertFromRaw(questionLabel);
      setEditorState(EditorState.createWithContent(contentState));
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [room.currentQuestion.value]);

  /* when responses are updated */
  useEffect(() => {
    let newCounterState = {
      for: 0,
      against: 0,
      abstain: 0,
    };
    if (room.currentQuestion.responses) {
      for (var i = 0; i < Object.keys(room.currentQuestion.responses).length; i++) {
        let key = Object.keys(room.currentQuestion.responses)[i];

        switch (room.currentQuestion.responses[key]) {
          case 'for':
            newCounterState.for++;
            break;
          case 'against':
            newCounterState.against++;
            break;
          case 'abstain':
            newCounterState.abstain++;
            break;
          default:
            break;
        }
      }
      setResponseCounter(newCounterState);
    }
  }, [room.currentQuestion.responses]);

  useEffect(() => {
    let stateToHTMLOptions = {
      blockStyleFn: (block: any) => {
        let data = block.getData();
        if (data.size === 0) return;
        let style = {};
        if (data.get('text-align'))
          style = {
            ...style,
            textAlign: data.get('text-align'),
          };
        return {
          style,
        };
      },
      inlineStyleFn: (styles: any) => {
        let size = styles.filter((value: string) => value.startsWith('fontsize-')).first();
        let color = styles.filter((value: string) => value.startsWith('color-')).first();
        let bgColor = styles.filter((value: string) => value.startsWith('bgcolor-')).first();

        let style = {};
        if (color)
          style = {
            ...style,
            color: color.replace('color-', ''),
          }; //color
        if (size)
          style = {
            ...style,
            fontSize: size.replace('fontsize-', ''),
          }; //fontsize
        if (bgColor)
          style = {
            ...style,
            backgroundColor: bgColor.replace('bgcolor-', ''),
          }; //background color

        if (color) {
          return {
            element: 'span',
            style: style,
          };
        }
      },
    };
    let htmlOutput = stateToHTML(editorState.getCurrentContent(), stateToHTMLOptions);
    setQuestionDisplay(htmlOutput);
  }, [editorState]);

  const answerToBgColour = {
    for: colours.plGreen,
    against: colours.plBrightPurple,
    abstain: colours.plGrey,
    '': 'transparent',
  };
  const answerToFontColour: { for: string; against: string; abstain: string } = {
    for: colours.plDarkPurple,
    against: colours.plStone,
    abstain: colours.plStone,
    // '': colours.plDarkPurple,
  };

  const isHost = club.role === Role.host;
  const clubsList = clubs.map((club: Club) => {
    const isClub = club.role === Role.club;
    const isConnected = room?.connectedClubs[club._id];

    return (
      isClub && (
        <ClubCard
          key={club._id}
          isConnected={isConnected}
          childrenColor={isConnected ? 'status-ok' : 'status-unknown'}
          shouldDisplayQrCode={isHost && !room.roomActive}
          title={club.label}
          club={club}
          logoSize="medium"
          logo={`/club_logos/400/${club._id}.png`}
          color={
            answerToBgColour[
              room?.currentQuestion?.responses &&
                (room?.currentQuestion?.responses[club._id] as keyof typeof answerToBgColour)
            ]
          }
          fontColour={
            answerToFontColour[
              room?.currentQuestion?.responses &&
                (room?.currentQuestion?.responses[club._id] as keyof typeof answerToFontColour)
            ]
          }
        />
      )
    );
  });

  const isFirstQuestion = room.currentQuestion.order === 1;
  const isLastQuestion = room.currentQuestion.order === room.questions.length;

  const HostPanel = () => (
    <Box display="flex" direction="row" flexGrow={1}>
      <Box
        display="flex"
        direction="column"
        flexGrow={1}
        border={`1px solid ${colours.plBrightPurple}`}
        zIndex="1"
        padding="10px"
        borderRadius="5px"
        overflowVertical="scroll"
        margin="0 10px"
      >
        {room?.currentQuestion?.order && !!questionDisplay && (
          <>
            {/*<QuestionTitle number={room?.currentQuestion?.order?.toString()} />*/}
            <Text
              size="xlarge"
              margin={{
                horizontal: 'small',
              }}
              dangerouslySetInnerHTML={{
                __html: questionDisplay,
              }}
            />
          </>
        )}
      </Box>
      <Box
        display="flex"
        width="200px"
        padding="10px"
        justify="space-between"
        alignItems="center"
        direction="column"
        gapX={10}
        flexShrink={1}
        margin="0 10px"
      >
        <Button onClick={onToggleLock}>
          <CheckBox
            tabIndex={Number('-1')}
            checked={!questionLocked}
            label={<Text>{!questionLocked ? 'Open' : 'Closed'}</Text>}
            toggle
          />
        </Button>
        <Button
          onClick={() => setOrderEditorOpen(!orderEditorOpen)}
          style={{
            borderRadius: '5px',
            padding: '20px 10px',
            width: 'max-content',
            fontWeight: 'bolder',
            color: colours.plBrightPurple,
            backgroundColor: colours.plLightPurple,
          }}
        >
          <Edit size="small" style={{ padding: '0 10px' }} color={colours.plBrightPurple} />
          Edit resolutions
        </Button>
        <Box display="flex" alignItems="center" gapX={20} direction="row">
          <Button disabled={isFirstQuestion} icon={<Left />} onClick={onClickPrevQuestion} />
          <Text color={colours.plDarkGrey}>
            {room.currentQuestion.order}/{room.totalQuestions}
          </Text>
          {isLastQuestion ? (
            <>
              {closeRoomWindow && (
                <Layer id="reconnecting" position="center">
                  <Box padding="48px" gapX={10} width="384px">
                    <Heading level={3} margin="none">
                      Room Closure
                    </Heading>
                    <Text style={{ margin: 0 }}>
                      Are you sure you want to save and close the room?
                    </Text>
                    <Box
                      display="flex"
                      gapX={10}
                      direction="row"
                      alignItems="center"
                      justify="end"
                      padding="24px 0 0px 0"
                    >
                      <Button
                        label="Cancel"
                        onClick={() => setCloseRoomWindow(false)}
                        color="dark-3"
                      />
                      <Button
                        label={
                          <Text color="white">
                            <strong>Close</strong>
                          </Text>
                        }
                        onClick={onClickNextQuestion}
                        primary
                        color={colours.plBrightPurple}
                      />
                    </Box>
                  </Box>
                </Layer>
              )}
              <Button icon={<FormSecure />} onClick={() => setCloseRoomWindow(true)} />
            </>
          ) : (
            <Button icon={<Right />} onClick={onClickNextQuestion} />
          )}
        </Box>
      </Box>
    </Box>
  );

  const DashboardPanel = () => (
    <Box
      display="flex"
      direction="column"
      flexGrow={1}
      border={`1px solid ${colours.plBrightPurple}`}
      zIndex="1"
      padding="10px"
      borderRadius="5px"
      overflowVertical="scroll"
      margin="0 10px"
    >
      {/*<QuestionTitle number={room?.currentQuestion?.order?.toString()} size={Size.xlarge} />*/}
      <Text
        size="xlarge"
        margin={{
          horizontal: 'small',
        }}
        dangerouslySetInnerHTML={{
          __html: questionDisplay,
        }}
      />
    </Box>
  );

  const ClubsGrid = () => (
    <Box
      display="grid"
      margin="10px"
      gridTemplateColumns="1fr 1fr 1fr 1fr"
      flexGrow={1}
      gapX={10}
      gapY={10}
    >
      {clubsList}
    </Box>
  );

  const ResponsesCards = () => (
    <Box display="flex" direction="row" gapX={10} minWidth="270px" margin="10px">
      <ResponsesCard
        fontColor={colours.plDarkGreen}
        label="FOR"
        color={colours.plGreen}
        value={responseCounter.for}
      />
      <ResponsesCard
        fontColor={colours.plStone}
        label="AGAINST"
        color={colours.plBrightPurple}
        value={responseCounter.against}
      />
      <ResponsesCard
        fontColor="white"
        label="ABSTAIN"
        color={colours.plGrey}
        value={responseCounter.abstain}
      />
    </Box>
  );

  return (
    <Box display="flex" direction="column" height="100vh" overflow="auto">
      <Box
        minHeight="200px"
        background="#F9F9F9"
        display="flex"
        direction="row"
        justify="space-between"
        gapX={10}
        padding="10px 0"
      >
        <AppBar side />
        {isHost ? <HostPanel /> : <DashboardPanel />}
      </Box>

      <ResponsesCards />

      <ClubsGrid />

      {orderEditorOpen && (
        <EditQuestionsOrderPopup
          broadcast={() => {
            socketUpdateQuestionsOrder(room.roomId, clubs);
          }}
          onClose={() => {
            setOrderEditorOpen(false);
          }}
        />
      )}
    </Box>
  );
};

const mapStateToProps: MapStateToProps<ConnectedProps, {}, AppState> = (state: AppState) => ({
  room: state.room,
  club: state.club,
});

export const RoomHostPanel = connect(mapStateToProps)(RoomHostPanelStaging);
